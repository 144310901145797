html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
}
@media screen and (min-width: 768px) {
  html {
    scroll-padding-top: 80px;
  }
}

.alice-carousel__next-btn {
  position: absolute;
  height: 40px;
  right: -5px;
  top: 35%;
  text-align: right;
}

.alice-carousel__prev-btn {
  position: absolute;
  left: -5px;
  top: 35%;
  text-align: left !important;
}

.active-scroll-spy {
  border-bottom: 1px solid;
  border-radius: 0;
  color: white;
  padding-bottom: 5px;
}

@media (max-width: 768px) {
  html {
    scroll-padding-top: 45px;
  }
}

/* Sidebar CSS */
.MuiPaper-root {
  background-color: #100b20 !important;
  color: white !important;
  height: 100dvh !important;
  width: 100% !important;
  padding: 3px 0 3px !important;
  border: none !important;
  position: relative !important;
}
.MuiListItemButton-gutters:hover {
  background-color: #190b33 !important;
}
.MuiListItemButton-gutters {
  z-index: 3000 !important;
}

.custom-button {
  background: linear-gradient(#100b20, #100b20) padding-box,
    linear-gradient(to right, #ff6b00 0%, #dd0077 55%, #7000ff 100%) border-box;
  border: 2px solid transparent;
}

.custom-button:hover {
  background: linear-gradient(transparent, transparent) padding-box,
    linear-gradient(
        to right,
        rgba(255, 107, 0, 0.8) 0%,
        rgba(221, 0, 119, 0.8) 55%,
        rgba(112, 0, 255, 0.8) 100%
      )
      border-box;
  border: 2px solid transparent;
}
.progress-container {
  width: 100%;
  height: 30px;
  background-color: #e0e0e0; /* Light gray background */
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
}

.progress-bar {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  border-radius: 10px;
  background: linear-gradient(
    to right,
    rgba(255, 107, 0, 0.8) 0%,
    rgba(221, 0, 119, 0.8) 55%,
    rgba(112, 0, 255, 0.8) 100%
  );
  transition: width 0.4s ease;
}

.pulse-border {
  animation: pulse 1s infinite ease-in-out;
}
@keyframes pulse {
  0% {
    border-width: 1px;
  }
  50% {
    border-width: 2px;
  }
  100% {
    border-width: 1px;
  }
}
/*  */
