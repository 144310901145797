@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */
@layer base {
  @font-face {
    font-family: 'Montserrat';
    src: url('../public/fonts/Montserrat/Montserrat-Regular.ttf');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Raleway';
    src: url('../public/fonts/Raleway/Raleway-Regular.ttf');
    font-weight: normal;
    font-style: normal;
  }

  .font-montserrat {
    font-family: 'Montserrat';
    font-weight: normal;
    font-style: normal;
  }
  .font-raleway {
    font-family: 'Raleway';
    font-weight: normal;
    font-style: normal;
  }

  /* css */
  body {
    padding: 0;
    margin: 0;
    font-family: 'Raleway', sans-serif;

    background: #100b20;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Montserrat', sans-serif;
  }
}
